import React from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

export default function Testimonials() {

  const data = useStaticQuery(graphql`
    query {
      allTestimonialsDataSet {
        nodes {
          Id
          Content
          StudentName
          StudentPicURL
        }
      }
    }
  `)

  const testimonials = data.allTestimonialsDataSet.nodes

  const settings = {
    dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, initialSlide: 0, autoplay: false, autoplaySpeed: 500, cssEase: "linear", adaptiveHeight: true
  };

  let strip;

  return (
    <div className="container testimonialOuter testimonials">
      <Slider {...settings}>
        {testimonials.length > 0 &&
          testimonials.map((testimonial, index) => {
            { strip = testimonial.Content.replace(/<\/?[^>]+(>|$)/g, "") }
            return (
              <React.Fragment key={index}>
                <div className="testimoial-card">
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-right" />
                  <FontAwesomeIcon icon={faQuoteRight} className="quote-left" />
                  <p className="testimonial-content" dangerouslySetInnerHTML={{ __html: strip }}></p>
                  <div className="testimonial-divider"></div>
                  <div className="testimonialImgWrapper d-flex align-items-center">
                    <img src={testimonial.StudentPicURL} alt={testimonial.StudentName} />
                    <h6>{testimonial.StudentName}</h6>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
      </Slider>
    </div>
  )
}