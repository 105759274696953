import React from 'react'
import Slider from 'react-slick'
import '../styles/CollegePartners.css'

const CollegePartners = () => {

    const settings = {
        dots: true,infinite: true, speed: 500,slidesToShow: 4,slidesToScroll: 1,initialSlide: 0, autoplay: false, autoplaySpeed: 500,cssEase: "linear",adaptiveHeight: true, responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
    
      ] };

    const collegePartners = [
        {
            "collegeName":"IEC College Greater Noida",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/iec-college-greater-noida.png"
        },
        {
            "collegeName":"Maratha Mandal Engineering College",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/maratha-mandal-engineering-college.png"
        },
        {
            "collegeName":"Navajyothi College",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/navajyothi-college.png"
        },
        {
            "collegeName":"Sri GCSR College Of Education",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/sri-gcsr-college-of-education.png"
        },
        {
            "collegeName":"Vedavyasa Institute Of Technology",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/vedavyasa-institute-of-technology.png"
        },
        {
            "collegeName":"Vidya Jyothi Institute Of Technology",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/vidya-jyothi-institute-of-technology.png"
        },
        {
            "collegeName":"VSM's SRK Institute Of Technology",
            "collegeImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/vsms-srk-institute-of-technology.png"
        }
    ]

    return (
        <div className="partnersOuter">
            <Slider {...settings}>
                {collegePartners.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="collegePartners">
                                <img src={item.collegeImage} alt={item.collegeName} />
                            </div>
                        </React.Fragment>
                    )
                })}
            </Slider>  
        </div>
    )
}

export default CollegePartners
